import {FormEvent, useEffect, useRef, useState} from 'react';
import {CONFIG} from '../../config';
import {useCreateUserMutation, useGetProductsQuery, useProfileQuery} from "../../store";

export function UserCreate() {
    const [createUser, {isLoading, error}] = useCreateUserMutation();
    const {refetch} = useProfileQuery();
    const {data: products = []} = useGetProductsQuery();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [panelName, setPanelName] = useState(CONFIG.panelNames[0]);
    const [productName, setProductName] = useState('');

    const closeRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (products.length > 0) {
            setProductName(products[0]?.name || '');
        }
    }, [products]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await createUser({email, name, panelName, productName}).unwrap();
            refetch();

            closeRef.current?.click();

            // clear the fields
            setEmail('');
            setName('');
        } catch (err: any) {
            console.error(err?.message || 'User creation failed');
        }
    };

    const renderedPanelNames = CONFIG.panelNames.map((name, index) => {
        return (
            <option key={index} value={name}>{name}</option>
        );
    });

    const renderedProductNames = products.map(({name}) => {
        return (
            <option key={name} value={name}>
                {name}
            </option>
        );
    });

    return (
        <div
            className="modal fade" id="add_user" tabIndex={-1} aria-labelledby="add_user" aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">Create User</h1>
                        <button
                            type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            ref={closeRef}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {error && (
                            <div className="alert alert-danger" role="alert" id="add_user_alert">
                                {(error as any).data?.message || 'Something went wrong!'}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} id="add_user_form">
                            <div className="mb-3">
                                <label htmlFor="">Email</label>
                                <input
                                    type="email" className="form-control form-control-sm mt-2" required min="1"
                                    placeholder="Email" name="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="">Name</label>
                                <input
                                    type="text" className="form-control form-control-sm mt-2" required min="1"
                                    placeholder="Name" name="name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="panelName">Panel Name</label>
                                <select className="form-select form-select-sm mt-2" aria-label="panel names"
                                        value={panelName}
                                        onChange={e => setPanelName(e.target.value)}
                                >
                                    {renderedPanelNames}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="productName">Product Name</label>
                                <select className="form-select form-select-sm mt-2" aria-label="product names"
                                        value={productName}
                                        onChange={e => setProductName(e.target.value)}
                                >
                                    {renderedProductNames}
                                </select>
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-sm btn-success w-100"
                                        name="add_user_btn"
                                        disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Add User'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
