import {FormEvent, useState} from 'react';
import {useDeleteUserMutation, User} from "../../store";
import {useNavigate} from "react-router-dom";
import {popUpMessage} from "../../utils";

interface UserDeleteProps {
    user: User;
}

export function UserDelete({user}: UserDeleteProps) {
    const {id: userId} = user;
    const navigate = useNavigate();

    const [deleteUser, {isLoading, error}] = useDeleteUserMutation();
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await deleteUser({userId}).unwrap();
            popUpMessage('User deleted successfully!')

            setShowModal(false);
            navigate('/users');
        } catch (err: any) {
            console.error(err?.message || 'Deleting user failed');
        }
    };

    return (
        <div className="row justify-content-center w-100 mb-4">
            <div className="col-12 col-md-8 col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Delete User</h5>
                        <p>Delete user with id: <code>{user.id}</code> permanently.</p>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-sm btn-danger"
                                onClick={() => setShowModal(true)}
                            >
                                Delete User
                            </button>
                        </div>
                        {showModal && (
                            <div className="modal fade show" tabIndex={-1} style={{ display: 'block' }} aria-labelledby="deleteUserModal">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteUserModal">Delete User</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => setShowModal(false)}
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="mb-2">Are you sure you want to delete this user?</p>
                                            {error && (
                                                <div className="alert alert-danger" role="alert">
                                                    {(error as any).data?.message || 'An error occurred while deleting the user.'}
                                                </div>
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => setShowModal(false)}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                form={`delete_user_${userId}_form`}
                                                className="btn btn-danger"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    'Yes, Delete'
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <form onSubmit={handleSubmit} id={`delete_user_${userId}_form`}
                              style={{display: 'none'}}>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
