import {toast} from "react-toastify";

export function popUpMessage(message: string) {
    toast.success(message,  {
        position: "top-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
    });
}
