import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AuthState {
    token: string | null;
}

const storedToken = localStorage.getItem("token");

const initialState: AuthState = {
    token: storedToken,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
            localStorage.setItem("token", action.payload.token || '');
        },
        logout: (state) => {
            state.token = null;
            localStorage.removeItem("token");
        },
    },
});

export const {
    setCredentials,
    logout,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
