import {FormEvent, useEffect, useState} from 'react';
import {useChargeUserMutation, useGetProductsQuery, useProfileQuery, User} from '../../store';
import {popUpMessage} from "../../utils";

interface UserChargeProps {
    user: User;
}

export function UserCharge({user}: UserChargeProps) {
    const {id: userId} = user;
    const [chargeUser, {isLoading, error}] = useChargeUserMutation();
    const {refetch} = useProfileQuery();
    const {data: products = []} = useGetProductsQuery();

    const [productName, setProductName] = useState('');

    useEffect(() => {
        if (products.length > 0) {
            setProductName(products[0]?.name || '');
        }
    }, [products]);

    const renderedProductNames = products.map(({name}) => {
        return (
            <option key={name} value={name}>
                {name}
            </option>
        );
    });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            await chargeUser({userId, productName}).unwrap();
            popUpMessage('User charged successfully!')
            refetch();
        } catch (err: any) {
            console.error(err?.message || 'Charging user failed');
        }
    };

    return (
        <div className="row justify-content-center w-100 mb-4">
            <div className="col-12 col-md-8 col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Charge User Account</h5>
                        <form onSubmit={handleSubmit} id={`charge_user_${userId}_form`}>
                            {error && (
                                <div className="alert alert-danger" role="alert">
                                    {(error as any).data?.message || 'Charging user failed'}
                                </div>
                            )}
                            <div className="mb-3">
                                <label htmlFor={`productSelect_${userId}`} className="form-label">
                                    Select Product
                                </label>
                                <select
                                    id={`productSelect_${userId}`}
                                    className="form-select form-select-sm"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                >
                                    {renderedProductNames}
                                </select>
                            </div>

                            <div className="mb-3">
                                <button
                                    type="submit"
                                    className="btn btn-sm btn-success w-100"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"></span>
                                    ) : (
                                        'Charge User'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
