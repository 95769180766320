import {Link, useNavigate, useParams} from 'react-router-dom';
import {useGetUserQuery} from '../../store';
import {NotFound} from "../NotFound";
import React from "react";
import {UserDetails} from "./UserDetails";
import {UserEdit} from "./UserEdit";
import {UserCharge} from "./UserCharge";
import {UserChangePanel} from "./UserChangePanel";
import {UserDelete} from "./UserDelete";

export function UserItem() {
    const {userId} = useParams<{ userId: string }>();
    const navigate = useNavigate();
    const {data: user, error, isFetching} = useGetUserQuery(userId || '');

    if (isFetching) {
        return <p>Loading user details...</p>;
    }

    if (error || !user) {
        return <NotFound/>;
    }

    return (
        <div className="container p-3">
            <div className="row">
                <div className="col-12 d-flex justify-content-center">
                    <Link to={`/users/sub/${user.token}`} target="_blank" rel="noopener noreferrer"
                          className="text-decoration-none">
                        <h2>View Subscription</h2>
                    </Link>
                    <button
                        onClick={() => navigate('/users')}
                        className="btn btn-secondary btn-sm ms-3">
                        Back to Users
                    </button>
                </div>
            </div>
            <div
                className="container min-vh-100 bg-light d-flex flex-column align-items-center justify-content-center p-4 mt-3">
                <UserDetails user={user}/>
                <UserDelete user={user}/>
                <UserEdit user={user}/>
                <UserCharge user={user}/>
                <UserChangePanel user={user}/>
            </div>
        </div>
    );
}

